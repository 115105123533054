<template>
  <div class="home-board-container" :style="{ 'font-size': fontSize + 'px' }">
    <div class="turnover-time">
      <span>更新时间：{{ time }}</span>
    </div>
    <!-- <div class="ismock_box"> -->
    <div v-if="(+isMock === 0 && !hasIncome) || +isMock === 1" class="ismock_box">
      <span>模拟数据</span>
      <a-switch :disabled="isLoading" size="small" :checked="+isMock === 1" @click="changeIsMock" />
    </div>
    <!-- <a-card class="home-board-box"> -->
    <div class="home-board-big-title">
      <div class="title">
        {{ dimensionTitle }}
        <a-popover overlayClassName="home-board-popover" placement="right">
          <template slot="content">
            <ul>
              <li @click="changeType(1)" :class="{ active: dimension === 1 }">今年收益</li>
              <li @click="changeType(0)" :class="{ active: dimension === 0 }">全部收益</li>
            </ul>
          </template>
          <a-icon class="select-icon" type="select" />
        </a-popover>
      </div>
      <!-- 数值过千加逗号，并保留几位小数
num: 当前数字 len: 小数点长度 str: 字符前添加字符串 -->
      <div class="value">
        <count-to :start-val="0" :decimals="2" :end-val="datas.sumRevenue" />
      </div>
    </div>
    <div class="small-title">
      <BoardItem
        v-for="(item, i) in dataList"
        :key="i"
        :value="datas[item.prop]"
        :title="item.title"
        :ratio="datas[item.ratio]"
      />
    </div>
    <!-- 背景图片 -->
    <!-- <waveSvg class="wave-svg" /> -->
    <!-- </a-card> -->
  </div>
</template>

<script>
import waveSvg from './waveSvg.vue'
import { numFormat } from '@/utils/dealNumber'
import { getSummaryData } from '@/api/dashboard'
import BoardItem from './boardItem.vue'
import moment from 'moment'
import { mapState } from 'vuex'
import CountTo from 'vue-count-to'
import { allMockData, yearMockData } from './mockData'
import autoFontSize from '@/mixins/autoFontSize'
export default {
  name: 'HomeBoard',
  mixins: [autoFontSize],
  components: { waveSvg, BoardItem, CountTo },
  data () {
    return {
      dimensionTitle: '',
      datas: {
        sumRevenue: 0
      },
      dimension: 0,
      isLoading: false,
      hasIncome: true,
      dataList: [
        {
          title: '昨日收益',
          prop: 'yesterdayRevenue',
          ratio: 'yesterdayRatio'
        },
        // {
        //   title: '前日收益',
        //   prop: 'beforeYesterdayRevenue',
        //   ratio: 'beforeYesterdayRatio'
        // },
        {
          title: '近七日收益',
          prop: 'sevenDaysRevenue',
          ratio: 'sevenDaysRatio'
        },
        {
          title: '本月收益',
          prop: 'monthRevenue',
          ratio: 'monthRatio'
        },
        {
          title: '上月收益',
          prop: 'lastMonthRevenue',
          ratio: 'lastMonthRatio'
        }
      ],
      time: ''
    }
  },
  created () {
    this.getTime()
    this.dimensionTitle = this.dimension === 1 ? '今年累计收益' : '累计收益'
    this.getHasIncome()
  },
  watch: {
    sysUserDashboardSetting: {
      handler (val) {
        this.dimension = val.dimension
        this.getSummaryData()
      },
      deep: true,
      immediate: true
    },
    isMock: {
      handler (val) {
        this.getSummaryData()
      }
    }
  },
  computed: {
    ...mapState({
      sysUserDashboardSetting: (state) => state.user.sysUserDashboardSetting,
      isMock: (state) => state.user.isMock
    })
  },
  methods: {
    async getHasIncome () {
      const { data = { sumRevenue: 0 } } = await getSummaryData({ dimension: 0 })
      this.hasIncome = !!data.sumRevenue
    },
    async changeIsMock () {
      if (this.isLoading) return
      await this.getHasIncome()
      if (this.hasIncome && +this.isMock !== 0) {
        this.$confirm({
          title: '提示',
          maskClosable: true,
          content: () => {
            return (
              <div class="method-modal" style="height:100px;display:flex;padding:30px">
                <div>
                  <a-icon
                    style="color: #fa8b15;font-size: 22px;margin-right: 20px;line-height:40px"
                    type="exclamation-circle"
                  />
                </div>
                <span>该账号已经有真实用户数据，关闭后将不能再次开启模拟数据功能，是否确认关闭。</span>
              </div>
            )
          },
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            this.$store.dispatch('changeIsMock', +this.isMock === 1 ? '0' : +this.isMock === 0 ? '1' : '')
          },
          onCancel: () => {},
          icon: () => {
            return ''
          },
          class: 'method-modal-wrapper',
          width: 600
        })
      } else {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
        this.$store.dispatch('changeIsMock', +this.isMock === 1 ? '0' : +this.isMock === 0 ? '1' : '')
      }
    },
    changeType (type) {
      this.dimension = type
      const sysUserDashboardSetting = {
        ...this.sysUserDashboardSetting,
        dimension: this.dimension
      }
      this.$store.dispatch('changeSysUserDashboardSetting', sysUserDashboardSetting)
    },
    numFormat,
    // async是一个异步函数，但是await配合async一起使用后，就相当于把异步函数变成了同步，
    // 因为await会等待他后面的表达式返回结果之后才执行下一步。
    async getSummaryData () {
      let resp
      if (+this.isMock === 0) {
        resp = await getSummaryData({ dimension: this.dimension })
      } else {
        if (+this.dimension === 0) {
          resp = allMockData
        } else if (+this.dimension === 1) {
          resp = yearMockData
        }
      }
      this.dimensionTitle = this.dimension === 1 ? '今年累计收益' : '累计收益'
      this.datas = resp.data
    },
    getTime () {
      const beginDay = new Date().getTime()
      // 今天
      const today = moment(new Date(beginDay))
      this.time = today.format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="less">
.home-board-popover .ant-popover-inner-content {
  padding: 0.21em;
  position: relative;
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    li {
      height: 2.14em;
      line-height: 2.14em;
      display: inline-block;
      width: 100%;
      text-align: center;
      cursor: pointer;
      &.active {
        color: @primary-color;
      }
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style lang="less" scoped>
::v-deep.home-board-container {
  // background-image: url('/images/home-board/background.png');
  user-select: none;
  position: relative;
  border-radius: 0.35em;
  overflow: hidden;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  min-width: 100%;
  // .home-board-box {
  // background: -moz-linear-gradient(left, #635cf7 0%, #4894f3 100%);
  // background: -webkit-linear-gradient(left, #635cf7 0%, #4894f3 100%);
  // background: -o-linear-gradient(left, #635cf7 0%, #4894f3 100%);
  // background: -ms-linear-gradient(left, #635cf7 0%, #4894f3 100%);
  // background: linear-gradient(to right, #635cf7 0%, #4894f3 100%);
  background-image: url('/images/home-board/background.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  // .ant-card-body {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  color: #333;
  flex-direction: column;
  padding: 1.42em;
  .ismock_box {
    padding-top: 0.21em;
    position: absolute;
    top: 0;
    left: 1.42em;

    span {
      font-size: 0.85em;
      margin-right: 0.5em;
      line-height: 2.14em;
    }
  }
  .home-board-big-title {
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 1.28em;
      height: 1.78em;
      line-height: 1.57em;
      z-index: 99;
      .select-icon {
        font-size: 1em;
        margin-top: 0.21em;
        vertical-align: top;
        z-index: 999999;
        cursor: pointer !important;
      }
    }
    .value {
      z-index: 10;
      font-size: 3.92em;
      font-weight: 700;
    }
  }
  .small-title {
    flex-grow: 1;
    display: flex;
    color: #333;
  }
  // }
  // }
  .turnover-time {
    position: absolute;
    top: 0;
    right: 1.42em;
    z-index: 100;
    padding: 0.35em 1.07em;
    border-radius: 0.35em;
    background: rgba(174, 170, 170, 0.2);
    color: #333;
    margin: 0.5em 0;
    font-size: 0.85em;
    color: #414141;
    // transform: scale(0.8);
  }
  .wave-svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // bottom: 0;
    left: 0;
  }
}
</style>
